
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    emits: ['reload'],
    data(): any {
        return {
            item: undefined as any,
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`users/${id}`)
                .then(({ data }: any) => {
                    this.item = data.data;
                    (this.$refs.form as any).setValues(this.item);
                });
        },
        saveItem(): void {
            this.put(`users/${this.$route.params.id}`, {
                ...this.item,
                client_id: this.item.client ? this.item.client.id : null,
            })
                .then(() => {
                    this.$router.push({ name: 'users.list' });
                    this.$emit('reload');
                });
        },
    },
});
